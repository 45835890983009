<template>
    <section>
        <div class="py-[30px] w-full lg:h-72 lg:pl-5 lg:border-[1px] lg:px-0 px-4 border-[#E1E1E1] rounded-2xl relative flex lg:justify-between overflow-hidden">
           <div class="grid gap-16 lg:gap-28">
                <div class="grid gap-2">
                    <p class="text-xl lg:text-3xl">{{t('Онлайн-банк')}}</p>
                    <p class="md:text-base text-sm "> {{t('Управляйте всеми')}} <br class="sm:hidden">{{t('услугами банка')}}<br>{{t('не выходя из дома')}}</p>
                </div>
                <div class="block lg:hidden">
                    <nuxt-link :to="localePath('/online24')">  <AButton width="mobile" class="!w-36 z-10 hover:border-2 hover:border-[#F9D849] hover:bg-white">{{t('Перейти')}}</AButton></nuxt-link>
                  
                </div>
                <div class="hidden lg:block">
                    <nuxt-link :to="localePath('/online24')"><AButton class="z-10 !w-36 hover:border-2 hover:border-[#F9D849] hover:bg-white">{{t('Перейти')}}</AButton></nuxt-link>
                    
                </div>
            <a-icon-online class="hidden lg:block absolute bottom-14 lg:bottom-0 -right-0 md:right-0 z-0"></a-icon-online>
            <a-icon-online-mobile  class="block lg:hidden absolute -right-20 top-7 z-0"></a-icon-online-mobile>
        </div>
           
        </div>
    </section>
</template>
<script setup>
const { t } = useI18n({ useScope: 'local' })
</script>
<i18n lang="json">{
    "ru": {
        "Онлайн-банк": "Онлайн-банк",
        "Управляйте всеми": "Управляйте всеми",
        "услугами банка": "услугами банка",
        "не выходя из дома": "не выходя из дома",
        "Перейти": "Перейти"
    },
    "en": {
        "Онлайн-банк": "Online Bank",
        "Управляйте всеми": "Manage all",
        "услугами банка": "bank services",
        "не выходя из дома": "without leaving home",
        "Перейти": "Go"
    },
    "es": {
        "Онлайн-банк": "Banco en línea",
        "Управляйте всеми": "Administra todos",
        "услугами банка": "los servicios bancarios",
        "не выходя из дома": "sin salir de casa",
        "Перейти": "Ir"
    },
    "ar": {
        "Онлайн-банк": "بنك عبر الإنترنت",
        "Управляйте всеми": "إدارة جميع",
        "услугами банка": "خدمات البنك",
        "не выходя из дома": "دون مغادرة المنزل",
        "Перейти": "انتقل"
    },
    "tr": {
        "Онлайн-банк": "Çevrimiçi Banka",
        "Управляйте всеми": "Tümünü yönetin",
        "услугами банка": "banka hizmetleri",
        "не выходя из дома": "evden çıkmadan",
        "Перейти": "Git"
    }
}</i18n>